/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType, useToasts } from '../../../contexts/ToastContext';
import { Roles } from '../../../models/Role';
import User, { UserStatuses } from '../../../models/User';
import ClientService from '../../../services/ClientService';
import PermissionUserItem from '../../../components/permissions/PermissionUserItem';
import { SearchInput } from '../../../components/shared/form-control/SearchInput';
import { ChevronIcon, ChevronType } from '../../../components/shared/icon/ChevronIcon';
import { SelectListMenu } from '../../../components/shared/SelectListMenu';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import InviteOrSearchModal from '../../../components/user/InviteOrSearchModal';
import UserInvitation from '../../../models/UserInvitation';
import { Option } from '../../../components/Option';
import AdminService from '../../../services/AdminService';
import TopNavPortal from '../../../components/layout/top-menu/TopNavPortal';
import { useNavigate, useParams } from 'react-router-dom';
import useDebounce from '../../../hooks/useDebounce';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import SortableHeading from '../../../components/shared/data-grid/SortableHeading';
import Loader from '../../../components/shared/Loader';
import StaticBreadCrumb from '../../../components/shared/breadcumb/StaticBreadCrumb';
import { Account } from '../../../models/Account';
import { useTopNavHeading } from '../../../global-state/Workspace';
import Button, { ButtonSize, ButtonType } from '../../../components/shared/form-control/Button';

const assignableRoles = [
  Roles.Consultant,
  Roles.ConsultantManager,
  Roles.Employee,
  Roles.ExternalAuditor,
  Roles.ExternalContributor,
  Roles.Management,
  Roles.SuperAdmin,
  Roles.TeamLead,
  Roles.TeamMember,
];

const Users: FC = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce(searchQuery, 700);
  const [filterOpen, setFilterOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState('+firstName');
  const [pageNumber, setPageNumber] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const { accountId } = useParams<{ accountId: string }>();
  const [account, setAccount] = useState<Account | null>(null);
  const navigate = useNavigate();
  const setTopNavheading = useTopNavHeading((x) => x.setValue);

  const { t } = useTranslation(['organisation', 'accounts', 'common']);
  const toasts = useToasts();

  const fetchUsers = useCallback(() => {
    setLoading(true);
    AdminService.getAccountUsers(accountId as string, {
      searchQuery: debouncedSearch,
      sortBy,
      pageNumber: pageNumber,
      statusCode: statusFilter || '',
    }).then((res) => {
      setUsers((prev) => [...(pageNumber > 1 ? prev : []), ...res.data]);
      setHasNextPage(res.hasNextPage || false);
      setLoading(false);
    });
  }, [debouncedSearch, pageNumber, sortBy, statusFilter, accountId]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (accountId) {
      AdminService.getAccount(accountId).then((res) => {
        setAccount(res.data);
        setTopNavheading(res.data.name);
      });
    }
    return () => {
      setAccount(null);
      setTopNavheading('');
    };
  }, [accountId, setTopNavheading]);

  // Reset to first page when search value changes
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const updateSortExpression = (field: string, expression: string) => {
    setSortBy(expression);
    setPageNumber(1);
  };

  const [lastElementRef] = useInfiniteScroll(hasNextPage ? () => setPageNumber((prev) => prev + 1) : null, loading);

  const statusOptions = useMemo<Option<string, string>[]>(() => {
    return [
      {
        id: 'enabled',
        text: t('common:user-status.enabled'),
        value: UserStatuses.Active,
      },
      {
        id: 'pending',
        text: t('common:user-status.pending'),
        value: UserStatuses.Pending,
      },
      {
        id: 'disabled',
        text: t('common:user-status.deactivated'),
        value: UserStatuses.Blocked,
      },
      {
        id: 'clear',
        value: null as any,
        text: t('common:list.filter.clear'),
      },
    ];
  }, [t]);

  const inviteUser = (userToInvite: UserInvitation) => {
    ClientService.inviteUser(userToInvite.clientId, userToInvite)
      .then((res) => {
        if (res.data.isNewUser) {
          toasts.addToast({
            title: t('organisation:permissions.toasts.invited'),
            description: t('organisation:permissions.toasts.invited-desc', {
              email: userToInvite?.email,
              client: userToInvite?.clientName,
            }),
            type: ToastType.SUCCESS,
            expiresInMs: 5000,
          });
        } else {
          toasts.addToast({
            title: t('organisation:permissions.toasts.invite-failed'),
            description: t('organisation:permissions.toasts.invite-failed-desc'),
            type: ToastType.INFO,
            expiresInMs: 10000,
          });
        }
        fetchUsers();
        setInviteUserModalOpen(false);
      })
      .catch((err) => {
        toasts.addToast({
          title: t('organisation:permissions.toasts.invite-failed'),
          description: err?.data?.meta?.message,
          type: ToastType.ERROR,
        });
      });
  };

  const onInviteClose = useCallback(() => {
    setInviteUserModalOpen(false);
  }, []);

  const inviteRoleFilter = useCallback((role: Option<string, string>) => {
    return assignableRoles.indexOf(role.value as Roles) > -1;
  }, []);

  const searchBox = (
    <>
      <div className="ml-4 w-80">
        <SearchInput
          data-cy="user-search"
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
          placeholder={t('common:permissions.user-filter-placeholder')}
        />
      </div>
      {accountId && (
        <Button data-cy="show-invite-modal" type={ButtonType.PRIMARY} size={ButtonSize.M} onClick={() => setInviteUserModalOpen(true)}>
          {t('common:permissions.buttons.invite')}
        </Button>
      )}
    </>
  );

  return (
    <div className="bg-background-1 flex min-h-full flex-col">
      <div className="flex h-full flex-col pt-6">
        <div className="flex items-center justify-between">
          <TopNavPortal>{!account && <Heading size={HeadingSize.H3}>{t('organisation:permissions.heading')}</Heading>}</TopNavPortal>
          <div className="flex w-full justify-between">
            {account && (
              <>
                <Heading size={HeadingSize.H3}>{t('organisation:permissions.heading')}</Heading>
                <div className="flex gap-2">{searchBox}</div>
              </>
            )}
            {!account && searchBox}
          </div>
        </div>

        <div className="flex flex-1 flex-col p-4">
          <div className="flex items-end border-l-2 border-transparent">
            <div className="w-2/6">
              <SortableHeading title={t('common:permissions.table-headings.firstname')} onSort={updateSortExpression} expression="+firstName" />
            </div>
            <div className="w-2/6">
              <SortableHeading title={t('common:permissions.table-headings.lastname')} onSort={updateSortExpression} expression="+lastName" />
            </div>
            <div className="w-2/6">
              <SortableHeading title={t('common:permissions.table-headings.email')} onSort={updateSortExpression} expression="+email" />
            </div>
            <div className="flex w-1/6 justify-start">
              <SelectListMenu
                onBlur={() => setFilterOpen(false)}
                isOpen={filterOpen}
                options={statusOptions}
                onClick={(o) => {
                  setStatusFilter(o.value as string);
                  setFilterOpen(false);
                }}
              >
                {(triggerProps) => (
                  <div {...triggerProps}>
                    {statusFilter && <div className="text-dpm-12">{t('common:permissions.table-headings.status')}</div>}
                    <span className="cursor-pointer underline" onClick={() => setFilterOpen(true)}>
                      {statusFilter ? statusOptions.find((x) => x.value === statusFilter)?.text : t('common:permissions.table-headings.status')}
                      <ChevronIcon type={ChevronType.DOWN} className="h-4 w-4" />
                    </span>
                    <div className="relative w-20"></div>
                  </div>
                )}
              </SelectListMenu>
            </div>
            <div className="w-10">{/* SPACER */}</div>
            <div className="w-10">{/* SPACER */}</div>
          </div>

          {users.length ? (
            users.map((user, i) => {
              const isLast = users.length === i + 1;
              return (
                <div key={`${user.id}.${i}`} ref={isLast ? lastElementRef : undefined}>
                  <PermissionUserItem user={user} onUserClick={() => navigate(`/admin/iam/users/${user.id}`)} />
                </div>
              );
            })
          ) : (
            <div data-cy="user-empty" className="text-dpm-20 text-color-1 flex min-h-32 w-full items-center justify-center font-medium">
              {t('common:permissions.no-users-found')}
            </div>
          )}
          {loading && (
            <div className="flex flex-col items-center py-6">
              <Loader size={16} centered={false} />
            </div>
          )}
        </div>

        {accountId && (
          <InviteOrSearchModal
            open={inviteUserModalOpen}
            onClose={onInviteClose}
            onInviteNew={inviteUser}
            filterRoles={inviteRoleFilter}
            clientId={accountId}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
